.grid-content {
  width: 100%;
  height: 100%;
  display: grid;
  // grid-auto-rows: 100px;
  grid-auto-rows: auto;
  grid-row-gap: 10px;
  border-radius: 10px;
  background: #fff;
  border: 1px solid #e3e8e7;

  .grid-item {
    border: none !important;
    border-radius: 0.2em;
    padding: 10px 10px 0 10px;
    position: relative;
  }
}

body[data-sidebar="dark"] {
  .grid-content {
    background: #272b34 !important;
    border: 1px solid #6b6c6d !important;
  }
}
