
@import './../../../../Pages/Analytics/font-variables';

// Email bubble

$the-bubble-bg-color: #563bff;
$the-bubble-bg-color-dark: white;
$bubble-text-color: white;


.bubble-bg-color-outer {
  color: #171c1b !important;
  font-size: 14px;
  font-family: "Montserrat", sans-serif;
  line-height: 1.25rem;
  font-weight: 400;
  border-radius: 20px 20px 0px 20px;
  background: #fff2cc;
  padding: 35px 22px 35px 22px;
  a {
    color: #171c1b !important;
    }
}

.bubble-bg-color-inner {
  color: #171c1b !important;
  font-size: $font-size-simple-text;
  font-family: $font-family-primary;
  line-height: $font-height-simple-text;
  font-weight: 400;
  border-radius: 20px 20px 20px 0px;
  background: #f6f8fb;
  padding: 35px 22px 35px 22px;
  a {
  color: #171c1b !important;
  }
}

.secondary-paragraph {
  color: $bubble-text-color;
}

.message-subject {
  font: normal 500 18px / normal $font-family-tertiary;
  color:#091b3d;
  white-space: nowrap;
}

.email-menu-bar {
  padding: 0 !important;
  margin-top: 20px !important;
  margin-bottom: 6px;
  justify-content: space-between !important;
  flex-wrap: nowrap !important;
  margin-left: auto !important;
  margin-right: auto !important;
}

.email-menu-bar-line {
  margin-top: 15px !important;
  margin-bottom: 40px;
  border-bottom: 1px solid #e3e8e7;
}

body[data-sidebar="dark"] {
  .inbox-conversation-stream {
    background: #363a45 !important;
    .message-subject,.message-headers  {
      color: #fff !important;
    }
    .email-menu-bar-line {
      border-bottom-color: #6b6c6d !important;
    }
    .bubble-bg-color-inner {
      background: #272b34;
      color: #fff;
    }
    .readEmailView_conatiner::-webkit-scrollbar {
          z-index: 999 !important;
          height: 3px !important;
    }
    
  }
}

@media (max-width: 1399.98px) { 
  .message-subject {
    font: normal 500 16px / normal $font-family-tertiary;
  }
}