/* EmailForm.css */

.email-container {
    margin: 0 auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #563bff14;
  }
  
  .header {
    font-size: 18px;
    text-align: center;
    margin-bottom: 20px;
  }
  
  .email-form {
    margin-bottom: 20px;
  }
  
  .input-field {
    width: 100%;
    margin-bottom: 10px;
    padding: 8px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-button {
    background-color: #4caf50;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
  .add-button,
  .generate-button {
    background-color: #008CBA;
    color: white;
    border: none;
    padding: 5px 10px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 10px;
  }
  
  .add-button:hover,
  .generate-button:hover {
    background-color: #005f6b;
  }
  
  .email-list {
    list-style: none;
    padding: 0;
  }
  
  .email-item {
    margin-bottom: 10px;
    font-size: 16px;
  }
  
  .email-form-wrapper {
    background-color: white;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  
  .add-button,
  .generate-button,
  .submit-button {
    transition: background-color 0.3s ease;
  }
  