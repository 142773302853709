@import '../Analytics/font-variables';

//   .font-primary {
//     font-family:  $font-family-primary;
//   }
  
//   .font-secondary {
//     font-family: $font-family-secondary;
//   }
//   .simple-text {
//     font-size: $font-size-simple-text;
//     line-height: $font-height-simple-text;
//   }

.page-box-content {
    background-color: white !important;
    padding: 0px 0px !important;
    margin-top: 60px !important;
    margin-bottom: 60px;
    height: 87vh;
}

.color-black {
    color: black !important;
}

/* 
.email-message {
    border-radius: 0 !important;
    padding: 30px 24px 30px 9px  !important;
    color: #000000 !important;
} */

.dashboard-email-message {
    border-radius: 12px !important;
    color: #000000 !important;
}

.dashboard-email-message:hover {
    border-radius: 12px !important;
    background-color: #f7f7f7 !important;
}

/* .email-message-text {
    color: #000000 !important;
    font: 600 13px $font-family-primary !important;
} */

.email-message-subject {
    /* color: #000000 !important; */
    font: 500 12px $font-family-primary !important;
    max-height: 1.2em !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    white-space: nowrap !important
}

/* .email-message-date {
    margin-left: auto;
    color: #000000 !important;
    font: 700 13px $font-family-primary !important;
} */

/* .column-title {
    color: #000000 !important;
    font: bold 17px Hammersmith One, sans-serif !important;
} */

.email-menu-title {
    margin-top: 2px !important;
    color: #141f27;
    font: 400 13px $font-family-primary !important;
}

.email-title-icon {
    color: #222222 !important;
    font: 18px $font-family-primary !important;
}

.email-menu-icon {
    color: #000000 !important;
    font-size: 16px !important;
}

.email-menu-active {
    color: #000000 !important;
    font-size: 16px !important;
    font-weight: bold !important;
}

.email-menu-dropdown {
    color: #222222 !important;
    font: 600 13px $font-family-primary !important;
    margin-top: 0px !important;
}

.email-menu-dropdown-active {
    color: #2f5cff !important;
    font: 500 13px $font-family-primary !important;
    margin-top: 0px !important;
    background-color: white !important;

}

.email-menu-dropdown:hover {
    color: #2f5cff !important;
    background-color: white !important;
}

/* .email-menu-icon {
    color: #c7c7d2 !important;
    font: 18px $font-family-primary !important;
} */

/* .email-menu-icon:hover {
    background-color: #ffedbb !important;
    padding: 0px 6px 0px 6px;
    color: #2f5cff;
    height: 25px !important;
    border-radius: 4px !important;
} */

.email-menu-bar-line {
    height: 1px;
    /* background-color: rgba(0, 0, 0, .1); */
    /* margin-bottom: 15px; */
    /* width: 100vw !important; */
    /* margin-left: -3%; */
}

.email-menu-bar {
    padding-top: 20px;
    padding-bottom: 15px;
    /* box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);   */
}

.font-weight-bold {
    color: #000000;
    font: bold 14px $font-family-primary !important;
}

// .accordion-new-button {
//     padding: 12px 1px !important;
//     font-weight: bold !important;
//     color: #000000 !important;
//     background-color: transparent !important;
// }

.accordion-line-button {
    padding: 12px 12px !important;
    font-weight: bold !important;
    color: #000000 !important;
    background-color: transparent !important;
    font-size: 16px !important;
}

.accordion-line-span {
    color: #000000 !important;
    font-size: 14px !important;
    font-weight: 400 !important;
}

.accordion-body-buttons {
    border-radius: 10px;
    background: #FF7049;
    border: none;
    box-shadow: 0px 16.21px 37.81px 0px rgba(255, 112, 73, 0.20);
    color: #FFF;
    font-family: $font-family-primary;
    font-size: $font-size-simple-text;
    font-style: normal;
    font-weight: 700;
    line-height: $font-height-simple-text;
    min-width: 151px;
    text-align: center;
    padding: 13px 10px;
    cursor: pointer;
}
.accordion-body-buttons-disabled {
    border-radius: 10px;
    background: rgb(239, 242, 247);
    border: none;
    color: #091b3d;
    font-family: $font-family-primary;
    font-size: $font-size-simple-text;
    font-style: normal;
    font-weight: 700;
    line-height: $font-height-simple-text;
    width: 151px;
    text-align: center;
    padding: 13px 10px;
    cursor: not-allowed;
}

/* .accordion-new-button:active{
   background-color: red !important;
} */
.accordion-new .accordion-item {
    border-top: 0 !important
}

.accordion-new .accordion-item {
    border-left: 0 !important
}

.accordion-new .accordion-item {
    border-right: 0 !important
}

.accordion-new .accordion-item {
    border-bottom: 1px solid black !important
}

.accordion-boutique .accordion-item {
    border-top: 1px solid #ced4da !important;
    border-radius: 0px !important;
    padding-top: 0px !important;
}

.accordion-boutique .accordion-item {
    border-left: 0 !important
}

.accordion-boutique .accordion-item {
    border-right: 0 !important
}

.accordion-boutique .accordion-item {
    border-bottom: 1px solid #ced4da !important
}

.accordion-line .accordion-item {
    border-top: 1px solid black !important
}

.accordion-line .accordion-item {
    border-left: 1px solid black !important
}

.accordion-line .accordion-item {
    border-right: 1px solid black !important
}

.accordion-line .accordion-item {
    border-bottom: 1px solid black !important
}

.accordion-bar .accordion-item {
    border-top: 0 !important
}

.accordion-bar .accordion-item {
    border-left: 0 !important
}

.accordion-bar .accordion-item {
    border-right: 0 !important
}

.accordion-bar .accordion-item {
    border-bottom: 0 !important
}

.accordion-body {
    padding: 10px 5px !important;
}

.detail-bar {
    color: black !important;
    font: 14px $font-family-primary !important;
}

p span {
    color: black !important;
    font: 14px $font-family-primary !important;
}

.stepContainer {
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    background: #FFF;
    box-shadow: 0px 6px 24px 1px rgba(53, 22, 252, 0.10);
    padding-bottom: 0px !important;
}

.stepTitle {
    color: #273532;
    font-family: $font-family-tertiary;
    font-size: 26px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

.stepSubTitle {
    color: #666969;
    font-family: $font-family-primary;
    font-size: $font-size-simple-text;
    font-style: normal;
    font-weight: 400;
    line-height: $font-height-simple-text;
}

.selected-tag {
    /* background-color: #FFEDBB !important;
    cursor: pointer;
    display: inline-block;
    border: 1px solid #FDD667;
    border-radius: 15px;
    padding: 3px 10px;
    margin-right: 10px;
    margin-bottom: 10px;
    font-size: 12px !important; */
    display: none;
}

.dataSource__container {
    border-radius: 15px;
    background: #FFF;
    box-shadow: 0px 6px 24px 1px rgba(53, 22, 252, 0.10);
}

.agentcard__Container {
    margin-top: 25px;
    border-radius: 15px !important;
    background: #FFF !important;
    box-shadow: 0px 6px 24px 1px rgba(53, 22, 252, 0.10) !important;

    .user-name {
        color: #151D48;
        font-family: $font-family-tertiary;
        font-size: 31.467px;
        font-style: normal;
        font-weight: 500;
        line-height: 47.2px;
    }

    .profile-activity {
        width: fit-content;
        position: relative;
        color: #737791;
        font-family: $font-family-tertiary;
        font-size: 27.533px;
        font-style: normal;
        font-weight: 400;
        line-height: 39.333px;
    }

    .profile-activity-dot {
        height: 10px;
        width: 10px;
        position: absolute;
        top: 6px;
        right: -16px;
    }
}

.mail-config_body,
.agentcard__Container, .store-config_body {
    .form-label {
        color: #171C1B;
        font-family: $font-family-primary;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
    }

    .form-control {
        height: 55px !important;
        border: none;
        border-radius: 10px;
        background: #F7F8FB;
        color: #737791;
        font-family: $font-family-primary;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &:focus {
            background: #F7F8FB;
        }
    }
}

.mail-config_body {
    .emailServer_div-selected {
        background : #fffaf1 !important;
        h5 {
        color:#FFA412 !important;
        }
}
}

.custom_range_slider {
    height: 8px;
    box-shadow: none;
    background: #e8e8e8;
    .rangeslider__fill {
        background-color: #7047ed;
        box-shadow: none;
    }

    .rangeslider__handle {
        border: 1px solid #7047ed;
        box-shadow: none;
        width: 20px;
        height: 20px;
        &::after {
            display: none !important;
        }
    }

    .rangeslider__handle-tooltip {
        background: #7047ed;
        &::after {
          border-top: 8px solid #7047ed !important;
        }
    }
    
    .rangeslider__handle-tooltip {
        user-select: none;
    }
    

    .rangeslider__labels {
        margin-bottom: 0 !important;
    }
}
.aiPower_container {
    border: 2px solid #7047ed;
    border-radius: 10px;
    width: 76.25px;
    flex-shrink: 0;
}
.aiPower_description__card {
    border-radius: 30px;
    background-color: #fff4d5;
    color: black !important;
}

.tag-not-selected {
    cursor: pointer;
    display: inline-block;
    background: #FFFAF1;
    border-radius: 12.206px;
    padding: 13px 26px;
    margin-right: 10px;
    margin-bottom: 10px;
    color: #FFA412;
    font-family: $font-family-primary;
    font-size: 15.109px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.no-bullets-list {
    list-style: none;
    padding: 0;
}

label[for="floatingSelectGrid"] {
    &::after {
        background-color: #f7f8fb !important;
    }
    
}

.tagsCustom_accordion {
    margin-top: 30px;

    .accordion-item {
        margin-bottom: 30px;
        padding: 11px 20px 11px 24px;
        border: none !important;
        border-radius: 15px !important;
        background: #FFF;
        box-shadow: 0px 6px 24px 1px rgba(53, 22, 252, 0.10);

        .accordion-text {
            color: #273532;
            font-family: $font-family-primary;
            font-size: 18px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }

        .accordion-line-span {
            margin-top: 5px;
            color: #747B7B !important;
            font-family: $font-family-primary;
            font-size: 14px !important;
            font-style: normal;
            font-weight: 400 !important;
            line-height: normal;
        }
    }

    .accordion-collapse {
        display: flex;
    }

    .form-control {
        color: #737791;
        font-family: $font-family-primary;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        border: none;
        height: 100%;
        border-radius: 10px;
        background: #F7F8FB;

        &:focus {
            background-color: #F7F8FB !important;
        }
    }
}

.inter-ligne {
    margin-top: 20px;
    margin-bottom: 20px;
    height: 1.1px;
    background-color: #e1e3e5;
}



.multi-range-slider {
    .thumb::before {
        height: 16px !important;
        width: 16px !important;
        box-shadow: none !important;
        margin: -5px -12px !important;
    }

    .bar {
        margin-bottom: 17px !important; 
    }

    .caption {
        position: absolute !important;
        bottom: -16px !important;
        width: 2px !important;
        height: 2px !important;
        left: -4px !important;
        display: flex !important;
        justify-content: center !important;
        align-items: center !important;
        overflow: visible !important;
        display: flex !important;
    }

    .max-caption {
        right: -10px;
    }

    .thumb {
        .caption * {
            /* background-color: #563BFF !important; */
            position: absolute !important;
            min-width: fit-content !important;
            height: fit-content !important;
            font-size: 75% !important;
            text-align: center !important;
            line-height: 30px !important;
            background-color: transparent !important;
            /* border-radius: 15px; */
            color: #302d2d !important;
            box-shadow: none !important;
            padding: 0px 5px !important;
            white-space: nowrap !important;
        }
    }

    .bar-inner {
        box-shadow: none !important;
    }
}