//
// _buttons.scss
//

button,
a {
    outline: none !important;
}

.btn-light {
    border: 1px solid darken($light, 2%);
}

// Rounded Buttons
.btn-rounded {
    border-radius: 30px;
}

.btn-dark,
.btn-secondary {
    color: $gray-200 !important;
}

.btn-outline-light {
    color: $gray-900;
}

//
// Soft Buttons
//

@mixin button-variant-soft($bg) {
    color: $bg;
    background-color: rgba($bg, 0.1);
    border-color: transparent;

    &:hover,
    &:focus,
    &:active {
        color: $white;
        background-color: $bg;
    }
}

@each $color, $value in $theme-colors {
    .btn-soft-#{$color} {
        @include button-variant-soft($value);
    }
}

.btn-group-vertical {
    label {
        margin-bottom: 0;
    }
}
/* Styles pour le bouton circulaire */
.btn-custom {
    border: none; /* Supprimez la bordure */
    background: rgba(255, 0, 0, 0.2); /* Rouge transparent */
    border-radius: 50%; /* Rend le bouton circulaire */
    width: 30px; /* Largeur souhaitée */
    height: 30px; /* Hauteur souhaitée */
    color: red; /* Couleur de l'icône */
    padding: 0; /* Supprime le rembourrage interne */
}

/* Styles pour l'icône X */
.ri-close-line {
    font-size: 20px; /* Taille de l'icône */
    line-height: 30px; /* Centrer verticalement l'icône dans le bouton */
}
/****************Custom colors****************/
 