@import './font-variables';

.Midnight-Blue {
    background-color: #563bff !important;
    color: white !important;
}

.cream {
    background-color: #FFEDBB !important;
}

.cream-card {
    border-radius: 10px !important;
}

.light-card {
    background-color: white !important;
    border-radius: 10px !important;
    box-shadow: none !important;
    border: 1px solid #dcdcdc !important;
}

.light-blue {
    overflow: hidden;
    position: relative !important;
    border-radius: 15px !important;
    background-color: #E8F7F7 !important;
    box-shadow: 0px 6px 24px 1px rgba(50, 153, 147, 0.13) !important;
    min-height: 176px;
    position: relative;
}

.emoji-title {
    /* color: black !important;
    font: 700 18px Bebas Neue, sans-serif !important; */
    // color: #F7F8FB !important;
    color: #091b3d !important;
    text-align: center !important;
    font-family: $font-family-tertiary !important;
    font-size: 28px !important;
    font-style: normal !important;
    font-weight: 600 !important;
    line-height: normal !important;
}

// .box-value-black {
//     color: #271d00 !important;
//     font: 700 22px Bebas Neue, sans-serif !important;
//     margin-top: 10px !important;
//     margin-bottom: 0px !important;
// }
.box-value-black {
    color: #000000 !important;
    font: 500 28px Bebas Neue, sans-serif;
    margin-top: 10px !important;
    margin-bottom: 0px !important;
}

.color-dark-brown {
    color: #271d00 !important;
}
.color-dark-black {
    color: #000000 !important;
}

.color-white {
    color: #ffffff !important;
}

.box-value-white {
    color: white !important;
    font: 700 22px Bebas Neue, sans-serif !important;
    margin-top: 10px !important;
    margin-bottom: 0px !important;
}

.box-icon-black {
    color: #271d00 !important;
}

// .box-title-black {
//     color: #271d00 !important;
//     font: 900 16px Montserrat, sans-serif !important;
// }
.box-title-black {
    color:#000000 !important;
    font: 900 14px Montserrat, sans-serif;
}

.box-title-white {
    color: white !important;
    font: 500 16px Montserrat, sans-serif;
}

h5 {
    color: black !important;
}

.email-title-button-icon {
    background-color: #f1f1f1 !important;
    padding: 0px 8px !important;
    border-radius: 5px !important;
    cursor: pointer;
}

/************************************************/
/* Style de l'icône au survol */

/* Style de l'icône */
.box-icon-white {
    cursor: pointer;
    color: white !important;
    font: 900 18px Montserrat, sans-serif !important;
}

.box-icon-black {
    cursor: pointer;
    color: #271d00 !important;
    font: 900 18px Montserrat, sans-serif !important;
}

.hover-text {
    position: absolute !important;
    width: 150px !important;
    bottom: 40px !important;
    left: 40%;
    border-radius: 4px !important;
    font-size: 14px !important;
    background-color: black !important;
    color: white !important;
    transition: opacity 0.3s ease-in-out;
    z-index: 999;
}

.hover-text-realtime-stats {
    padding: 4px 6px;
    position: absolute !important;
    height: fit-content !important;
    width: 150px !important;
    bottom: 80% !important;
    border-radius: 4px !important;
    font-size: 14px !important;
    background-color: black !important;
    color: white !important;
    transition: opacity 0.3s ease-in-out;
    z-index: 999; 
    right:0 !important;
}

.hover-text-dashboard {
    position: absolute !important;
    width: 150px !important;
    /* bottom: 40px !important; */
    right: 40px;
    border-radius: 4px !important;
    font-size: 14px !important;
    background-color: black !important;
    color: white !important;
    transition: opacity 0.3s ease-in-out;
    z-index: 999;
}

.dashboard-clients-icon {
    color: #ffc91f !important;
    font: 900 32px Montserrat, sans-serif !important;
}

.dashboard-clients:hover {
    background-color: #f7f7f7 !important;
    border-radius: 10px !important;
    cursor: pointer;
}

/* .globale-bar {
    color: rgb(0, 0, 0) !important;
    border-radius: 8px !important;
    margin-top: 5px !important;
    height: 40px;
} */

.vertical-align {
    display: flex;
    flex-direction: column;
    margin-right: 10px;
}

.word {
    margin-top: 30px;
    font-weight: bold;
    /* font: bold Montserrat, sans-serif !important; */
}

.page-title-box {
    padding-bottom: 24px;
    font-family: Montserrat ,sans-serif;
    .breadcrumb {
        background-color: transparent;
        padding: 0;
        font-family: Montserrat ,sans-serif;
    }

  .page-title {
    color:  #091b3d;
    font-family: $font-family-tertiary;
    font-size: 36px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  .page-title-right {
    align-items: center;
    gap: 20px;
  }
}