
@import './../../../../Pages/Analytics/font-variables';

// 
// authentication.scss
//
.bg-pattern {
    background-image: url("../../../images/bgImage.png");
    background-size: auto;
    background-position: left top;
}

.auth-logo {
    &.logo-light {
        display: $display-none !important;
    }

    &.logo-dark {
        display: $display-block !important;
    }
}


.auth-card {
    box-shadow: 0px 6px 24px 1px rgba(53, 22, 252, 0.10);
    border-radius: 15px;
    .card-body {
        padding: 0;
    }
}

.auth-card__left {
    background-color: #EBEBEB;
    box-shadow: 0px 6px 24px 1px rgba(53, 22, 252, 0.10);
    z-index: 1;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    min-height: 80vh;

    display: flex;
    align-items: center;
}

.form-control-recoverpw {
    height: 55px;
    border-radius: 10px;
    background-color: #F2F4FB !important;
    border: none;
    color: #737791 !important;
    font-family: $font-family-primary;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.login-card__right {
    background-color: white;
    box-shadow: 0px 6px 24px 1px rgba(53, 22, 252, 0.10);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
    .form-control {
        height: 55px;
        border-radius: 10px;
        background-color: #F2F4FB !important;
        border: none;
        color: #737791 !important;
        font-family: $font-family-primary;
        font-size: 15px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
    }
}

.auth-card_image-div {
    background-image: url("../../../images/bgImage.png");
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
    height: 80%;
    width: 100%;
    padding-left: 14px;
    padding-right: 13px;
}

.sucess-message {
    max-width: 716px;
    max-height: 595px;
    border-radius: 30px;
    background: #FFF;
    padding: 63px;
}
.login2FA__title {
    color: #FF7049;
    text-align: center;
    font-family: $font-family-tertiary;
    font-size: 22px;
    font-style: normal;
    font-weight: 600;
    line-height: 116.25%; /* 25.575px */
    text-transform: capitalize;
}
.login2FA__h2 {
    color:  #091B3D;
    text-align: center;
    font-family: $font-family-tertiary;
    font-size: 36px;
    font-style: normal;
    font-weight: 600;
    line-height: 116.25%;
    text-transform: capitalize;
}

.login2FA__p {
    color: #898989;
    text-align: center;
    font-family: $font-family-primary;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%; /* 23.4px */
    text-transform: capitalize;
}

.login2FA__optionButton {
    color: #329993;
    font-family: $font-family-primary;
    border: none;
    border-radius: 10px;
    padding: 13px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    font-size: 17.005px;
    font-style: normal;
    font-weight: 500;
    line-height: 29.152px; /* 171.429% */
    background-color: #E8F7F7;
    box-shadow: 0px 19.683547973632812px 45.92827606201172px rgba(203, 240, 240, 0.45);

}

.login2FA__optionSelected {
    box-shadow: 0px 19.683547973632812px 45.92827606201172px rgba(255, 112, 73, 0.20);
}

.login2FA__optionButtonSelected {
    color: white;
    background-color: #FF7049;
}

.inputCustom__container {
    border-radius: 10px;
    background: #F2F4FB;
    padding: 6px 8px;
}

.inputCustom__Button {
    border-radius: 10px;
    color: #FFF;
    font-family: $font-family-primary;
    font-size: 14.827px;
    font-style: normal;
    font-weight: 500;
    width: 100%;
    line-height: 25.418px;
    padding: 9px 14px;
    background-color: #FF7049;
    box-shadow: 0px 17.162748336791992px 40.04641342163086px rgba(255, 112, 73, 0.20);
}

.login2FA__codeInput {
    width: 100%;
    border: none;
    padding-left: 42%;
    height: 69px;
    border-radius: 10px;
    background: #F2F4FB;
}

.intl-tel-input {
    .form-control{
        width: 100%;

        &:focus {
            background-color: transparent !important;
        }
    }
    .divider {
        margin-top: 5px !important;
        margin-bottom: 5px !important;
        border-image: none !important;
    }
}


.form-label {
    color: #171C1B;
    font-family: $font-family-tertiary;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

// .form-control {
//     height: 55px;
//     border-radius: 10px;
//     background-color: #F2F4FB !important;
//     border: none;
//     color: #737791 !important;
//     font-family: $font-family-primary;
//     font-size: 15px;
//     font-style: normal;
//     font-weight: 400;
//     line-height: normal;
// }

.form-check {
    .form-label {
        font-size: 14px;
        font-weight: 400;
    }
}



.slide-out-left {
	-webkit-animation: slide-out-left 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
	        animation: slide-out-left 0.3s cubic-bezier(0.550, 0.085, 0.680, 0.530) both;
}
.slide-in-right {
	-webkit-animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-in-right 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}













@media (max-width: 1399.98px) {
    .login2FA__optionButton {
        font-size: 12px !important;
        line-height: normal !important;
    }
}

/**
 * ----------------------------------------
 * animation slide-out-left
 * ----------------------------------------
 */
 @-webkit-keyframes slide-out-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-100px);
              transform: translateX(-100px);
      opacity: 0;
      display: none;
    }
  }
  @keyframes slide-out-left {
    0% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateX(-100px);
              transform: translateX(-100px);
      opacity: 0;
      display: none;
    }
  }
  /**
 * ----------------------------------------
 * animation slide-in-right
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-right {
    0% {
        display: block !important;
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-right {
    0% {
        display: block !important;
      -webkit-transform: translateX(100px);
              transform: translateX(100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateX(0);
              transform: translateX(0);
      opacity: 1;
    }
  }
  