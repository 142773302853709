//
// _footer.scss
//

.footer {
  border-top: none;
  bottom: 0;
  padding: 20px calc(#{$grid-gutter-width} / 2);
  position: absolute;
  right: 0;
  color: $footer-color;
  left: $sidebar-width;
  height: $footer-height;
  box-shadow: $box-shadow;
  background-color: $footer-bg;
}

@media (max-width: 992px) {
  .footer {
    left: 0;
  }
}

// Enlarge menu
.vertical-collpsed {
  .footer {
    left: $sidebar-collapsed-width;
  }
}

body[data-layout="horizontal"] {
  .footer {
    left: 0 !important;
  }
}

body[data-sidebar="dark"] {
  .footer {
    // border-top-color: #383a45 !important;
    color: $footer-dark-color;
    background-color: $footer-dark-bg;
  }
}
