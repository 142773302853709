@import "../Analytics/font-variables";

.simple-text {
  font-family: $font-family-primary;
  font-size: $font-size-simple-text;
  line-height: $font-height-simple-text;
}

.page-title-box {
  padding-bottom: 24px;
  font-family: Montserrat, sans-serif;

  .breadcrumb {
    background-color: transparent;
    padding: 0;
    font-family: Montserrat, sans-serif;
  }

  .page-title {
    color: #091b3d;
    font-family: $font-family-tertiary;
    font-size: 36px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }

  .page-title-right {
    align-items: center;
    gap: 20px;
  }
}

.overview_row {
  .card {
    border-radius: 10px;
    background: #fff;
    border: 1px solid #dcdcdc;
    box-shadow: none;
  }
  .card-body {
    padding: 2rem;
  }
}

.section__title__md {
  color: #000000;
  font-family: $font-family-primary;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* white-space: nowrap;
  overflow: hidden; */
  /* text-overflow: ellipsis; */
  > span {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -30%);
  }
}

.section__title {
  // color: #091b3d !important;
  color: black !important;
  font-family: $font-family-primary;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  /* text-transform: capitalize; */
  position: relative;
  margin-bottom: 28px;

  > span {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(40%, -36%);
  }
}

.actions__container {
  display: flex;
  flex-wrap: nowrap;
  height: fit-content;
  align-items: center;
}

.info-icon {
  position: absolute;
  top: 0;
  right: 0;
  transform: translate(50%, -20%);
}

.Blue {
  background: #e8f7f7 !important;
  border-radius: 20.729px !important;
}

.Midnight-Blue {
  background-color: #563bff !important;
  color: white !important;
}

.cream {
  background-color: #ffedbb !important;
}

.cream-card {
  border-radius: 10px !important;
}

.light-card {
  background-color: white !important;
  border-radius: 10px !important;
  box-shadow: none !important;
  border: 1px solid #dcdcdc !important;
}

.emoji-title {
  color: black !important;
  font: 700 18px Bebas Neue, sans-serif !important;
}

/* .emoji-title {
  color: black !important;
  font: 700 18px Bebas Neue, sans-serif !important;
} */

.box-value-black {
  color: #271d00 !important;
  font: 500 28px Bebas Neue, sans-serif !important;
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}

.color-dark-brown {
  color: #271d00 !important;
}

.color-white {
  color: #ffffff !important;
}

.box-value-white {
  color: white !important;
  font: 700 55px Bebas Neue, sans-serif !important;
  margin-top: 10px !important;
  margin-bottom: 0px !important;
}

.box-icon-black {
  color: #271d00 !important;
}

.box-title-black {
  color: #271d00 !important;
  font: 900 16px Montserrat, sans-serif;
}

.box-title-white {
  color: white !important;
  font: 500 16px Montserrat, sans-serif;
}

h5 {
  color: black !important;
}

.email-title-button-icon {
  background-color: #f1f1f1 !important;
  padding: 0px 8px !important;
  border-radius: 5px !important;
  cursor: pointer;
}

h3 {
  color: #000 !important;
}

/************************************************/
/* Style de l'icône au survol */

/* Style de l'icône */
.box-icon-white {
  cursor: pointer;
  color: white !important;
  font: 900 18px Montserrat, sans-serif !important;
}

.box-icon-black {
  cursor: pointer;
  color: #271d00 !important;
  font: 900 18px Montserrat, sans-serif !important;
}

.hover-text {
  position: absolute !important;
  width: 150px !important;
  bottom: 40px !important;
  left: 40%;
  border-radius: 4px !important;
  font-size: 14px !important;
  background-color: black !important;
  color: white !important;
  transition: opacity 0.3s ease-in-out;
  z-index: 999;
}

.hover-text-dashboard {
  position: absolute !important;
  width: 150px !important;
  /* bottom: 40px !important; */
  right: 40px;
  border-radius: 4px !important;
  font-size: 14px !important;
  background-color: black !important;
  color: white !important;
  transition: opacity 0.3s ease-in-out;
  z-index: 999;
}

.dashboard-clients {
  flex-direction: column;
  padding: 30.49px 15.19px 24.07px 24.46px;
  width: 323.658px;
  flex-shrink: 0;
  border-radius: 10px !important;
  background: #fff;
  border: 1px solid #dcdcdc;

  > p {
    color: #091b3d;
    font-family: $font-family-primary;
    font-size: $font-size-simple-text;
    font-style: normal;
    font-weight: 300;
    line-height: $font-height-simple-text;
    margin: 0 !important;
  }
}

.dashboard-clients-icon_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50.534px;
  height: 50.534px;
  border-radius: 10.829px;
  background: #fffaf1;
  margin-bottom: 8px;
}

.dashboard-clients:hover {
  background-color: #f7f7f7 !important;
  border-radius: 10px;
  cursor: pointer;
}

.globale-bar__container {
  position: relative;

  &::before {
    content: "";
    position: absolute;
    height: 30px;
    width: 100%;
    opacity: 0.16;
    border-radius: 38.966px;
    background: rgb(246, 247, 249);
    box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.12) inset;
  }
}

.globale-bar {
  border-radius: 38.966px !important;
  margin: 6px 7px;
  height: 18px;
}

.customGraph_bar__container {
  position: relative;
  display: flex;
  align-items: center;
  &::before {
    content: "";
    position: absolute;
    height: 24px;
    width: 100%;
    opacity: 0.16;
    border-radius: 38.966px;
    background: #f6f7f9;
    box-shadow: 0px 4px 17px 0px rgba(0, 0, 0, 0.12) inset;
  }
}

.customGraph_bar {
  border-radius: 38.966px !important;
  margin: 6px 7px;
  height: 16px;
}

.divider {
  margin: 18px 12px !important;
  overflow: hidden;
  border: 1px solid transparent;
  border-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='1145' height='2' viewBox='0 0 1145 2' fill='none'%3E%3Cpath d='M1 1L1144 0.9999' stroke='%23E6E7E9' stroke-linecap='round' stroke-dasharray='8 8'/%3E%3C/svg%3E")
    1 repeat;
}

.TabWiz__container {
  z-index: 2 !important;
  padding: 18px 0 18px 15px !important;
  margin-bottom: 44px;
  align-self: end;
  background-color: white !important;
  border-radius: 92px 0px 0px 92px;
  box-shadow: 0px 16px 37px 0px #563bff42;
  -webkit-clip-path: inset(-37px 0px -100% -37px);
  clip-path: inset(-37px 11px -100% -37px);
  transition: none !important;
}

.TabWiz {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  left: -12px;
  border-radius: 50%;
  margin: 0px 0 24px 20px;
  background-color: white;
  box-shadow: 0px 16px 37px 0px #563bff42;

  img,
  svg,
  .TabWizActive__text {
    display: none !important;
  }
}

.TabWizCompleted {
  background-color: #3115e2;
  left: -12px;

  .TabWiz__text_1 {
    color: white !important;
  }

  .TabWiz__text_2 {
    color: white !important;
  }
}

.TabWiz__text_1 {
  color: #c7c7d2;
  font-family: $font-family-primary;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 93%;
  margin: 5px 0 5px 0;
}

.TabWiz__text_2 {
  color: #c7c7d2;
  font-family: $font-family-primary;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 88%;
}

.TabWizActive__text_1 {
  color: #fff;
  text-align: center;
  font-family: $font-family-primary;
  font-size: 10px;
  font-style: normal;
  font-weight: 400;
  line-height: 93%;
  margin: 5px 0 5px 0;
}

.TabWizActive__text_2 {
  color: #fff;
  font-family: $font-family-primary;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 88%;
}

.nav-link {
  transition: none !important;
}

.TabWizGrey {
  height: 3px;
  width: 30px;
  border-radius: 10px;
  background-color: grey;
}

.TabWizActive {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background-color: white;
  box-shadow: 0px 16px 37px 0px #563bff42;

  .TabWiz__text {
    display: none !important;
  }

  .TabWizActive__text {
    z-index: 3;
    position: relative;
    display: flex !important;
    left: 6px;
  }
}

.link-butons-next {
  margin-left: auto;
  display: flex;
  align-items: center;
  gap: 5px;
  color: #563bff;
  font-family: $font-family-primary;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.link-butons-prev {
  display: flex;
  align-items: center;
  gap: 5px;
  color: #737791;
  font-family: $font-family-primary;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.link-butons-prev-disabled {
  display: none;
}

ul.checkbox-list {
  list-style-type: none;
  padding-left: 0;
}

ul.checkbox-list li {
  margin-bottom: 10px;
  /* Ajoutez de l'espace entre les éléments de liste */
}

ul.checkbox-list label {
  font-weight: 400;
  display: flex;
  align-items: center;
  /* Centrez verticalement le texte par rapport à la case à cocher */
}

.btn-cream {
  background-color: #6d6d6d !important;
  color: #ffffff !important;
}

.btn-line {
  border: 1px solid#6d6d6d !important;
}

.btn-purple {
  background-color: #563bff !important;
  color: #ffffff !important;
}

.card-style {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.05);
}

.attention_requested {
  .close-btn {
    border: none;
    padding: 6px 18px 6px 19px;
    border-radius: 10px;
    background: #f10944;
    box-shadow: 0px 14px 36px 0px rgba(255, 73, 73, 0.2);
    /**/
    color: #fff;
    text-align: center;
    font-family: $font-family-primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 24px;
    /* 171.429% */
  }

  .accordion-item {
    background: white;
    border-bottom: none;
    border-radius: 12.296px !important;
    margin-bottom: 30.15px !important;

    .accordion-button {
      border-radius: 12.296px !important;
      padding: 25px;
      background-color: white;
      border-top-left-radius: 12.296px !important;
      border-top-right-radius: 12.296px !important;
      color: #171c1b;
      font-family: $font-family-primary;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      box-shadow: none;
      .title {
        font: inherit !important;
        color: #171c1b;
        white-space: nowrap; // Empêche le texte de passer à la ligne suivante
        overflow: hidden; // Cache le texte qui dépasse
        text-overflow: ellipsis; // Affiche des points de suspension (...) lorsque le texte est tronqué
      }

      &::after {
        box-shadow: 0px 6.14783px 24.59133px 1.02464px rgba(53, 22, 252, 0.1);
        width: 30.739px;
        height: 30.739px;
        border-radius: 50%;
        background-position: center center;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='14' height='11' viewBox='0 0 14 11' fill='none'%3E%3Cpath d='M0.391661 10.2794C0.56012 10.7741 0.946992 10.9398 1.42688 10.9808C1.75415 11.0087 2.08142 10.998 2.40766 10.998C5.81819 10.9998 9.22871 10.9998 12.6392 10.998C12.8993 10.998 13.1594 10.9828 13.4082 10.8847C13.8691 10.7017 14.0799 10.3073 13.9724 9.8205C13.9084 9.53009 13.7754 9.27206 13.6097 9.02988C11.8987 6.52952 10.1877 4.02961 8.47667 1.53017C8.26791 1.22563 8.01332 0.970704 7.65677 0.842896C7.14312 0.658589 6.68425 0.768484 6.27292 1.11298C5.99732 1.34448 5.81922 1.65419 5.61838 1.94529C4.01463 4.28328 2.41294 6.6223 0.813325 8.96236C0.626745 9.22603 0.484328 9.51831 0.391661 9.82773V10.2794Z' fill='%23563BFF' fill-opacity='0.75'/%3E%3C/svg%3E");
      }
    }

    .accordion-collapse {
      padding: 0 25px 20px 25px;

      .accordion-body {
        padding: 0 !important;

        .accordion-body__text {
          margin-bottom: 16.45px;
          color: #747b7b !important;
          font-family: $font-family-primary !important;
          font-size: 14.345px !important;
          font-style: normal !important;
          font-weight: 400;
          line-height: normal !important;
        }
      }

      .action-btn {
        /* padding: 6.15px 20.49px 6.15px 19.47px; */
        width: 101.439px;
        border: none;
        border-radius: 10.246px;
        /**/
        color: #fff;
        text-align: center;
        font-family: $font-family-primary;
        font-size: 14.345px;
        font-style: normal;
        font-weight: 500;
        line-height: 24.591px;
      }
    }
  }

  .false {
    // box-shadow: 0px 6.14783px 24.59133px 1.02464px rgba(53, 22, 252, 0.1);
    border: 1px solid #dcdcdc !important;
  }
  .deployed {
    // box-shadow: 0px 6.14783px 24.59133px 1.02464px rgba(53, 22, 252, 0.1);
    border: 2px solid #dcdcdc !important;

    /* .accordion-button {
      padding-bottom: 10px !important;
    } */
  }
  .attention_requested-button {
    border: none;
    background-color: #563bff;
    border-radius: 18px;
    color: #fff;
    padding: 13.36px 10.93px 12.15px 12.15px;
    font-weight: 500;
    font-family: $font-family-primary;
    font-size: $font-size-simple-text;
    line-height: $font-height-simple-text;
    gap: 8px;
    min-width: fit-content;
    width: 26%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.client-feeling {
  .feelings__container {
    position: relative;
    overflow: hidden;

    .top-left__div {
      position: absolute;
      transform: translate(-38%, -84%);
      width: 294.668px;
      height: 294.668px;
      border-radius: 294.668px;
      background: rgba(255, 250, 241, 0.19);
    }

    .top-right__div {
      position: absolute;
      transform: translate(16%, -90%);
      width: 91.336px;
      height: 91.336px;
      border-radius: 91.336px;
      right: 0;
      border: 1px solid #fffaf1;
    }

    .bottom-right__div {
      position: absolute;
      bottom: 0;
      transform: translate(120%, 64%);
      width: 294.668px;
      height: 294.668px;
      border-radius: 294.668px;
      background: rgba(255, 250, 241, 0.19);
    }

    .bottom-right-outlined__div {
      position: absolute;
      bottom: 0;
      right: 0;
      transform: translate(-40%, -110%);
      width: 45.668px;
      height: 45.668px;
      border-radius: 45.668px;
      border: 1px solid #ce7e00;
    }

    img {
      width: 11vh !important;
      height: 11vh;
    }
  }
}

.languages__card {
  background-color: white;
  border-radius: 20px;
  box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.05);
}

.attention_languages {
  background-color: white;
  border-radius: 10px !important;
  padding: 2rem !important;
  border: 1px solid #dcdcdc !important;
}

.attention_languages__language {
  .card {
    position: relative;
    height: 100%;
    box-shadow: none !important;
    border-left: 2px solid transparent !important;
    border-radius: 0;
  }

  .card-body {
    padding: 0 0 0 30px !important;
    height: 100%;
  }
}

.attention_languages__attention {
  .customLegend {
    margin-top: 25px;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;

    .legend {
      margin-right: 19px;
      width: 20px;
      height: 20px;
      border-radius: 5px;
    }

    .dissatisfied {
      background: #ff7049;
    }

    .neutral {
      background: grey;
    }

    .satisfied {
      background: #329993;
    }

    span {
      color: #5e5e5e;
      font-family: $font-family-primary;
      font-size: $font-size-simple-text;
      font-style: normal;
      font-weight: 500;
      line-height: $font-height-simple-text;
    }
  }
}

.attention_languages {
  .recharts-wrapper {
    background-color: #f8f9ff !important;
    border-radius: 20px;
  }
}

.attention_languages__text {
  color: #171c1b;
  font-family: $font-family-primary;
  font-size: $font-size-simple-text;
  font-style: normal;
  font-weight: 400;
  line-height: $font-height-simple-text;
  /* 17.64px */
}

.realtime-stats {
  .cream-light {
    border-radius: 10px !important;
    background: #fffaf1 !important;
    margin-bottom: 31px !important;

    .card-body {
      padding-top: 10px;
      padding-bottom: 10px;
      padding-left: 12px;
      padding-right: 12px;
    }
  }

  .stats-icon__div {
    flex-shrink: 0;
    width: 42px;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15.577px;
    background: #ffa412;
    box-shadow: 0px 13.16458px 33.85176px 0px rgba(243, 200, 47, 0.24);
    margin-right: 31px;
  }

  .box-value-dark-blue {
    color: #091b3d !important;
    font-family: Oswald, sans-serif;
    font-size: 36.658px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin: 0 !important;
  }

  .color-dark-blue {
    color: #091b3d !important;
    font-size: 14px !important;
  }

  .box-title-dark-blue {
    color: #091b3d !important;
    font-family: $font-family-primary;
    font-size: 13px;
    font-style: normal;
    font-weight: 500;
    line-height: $font-height-simple-text;
    max-width: 80%;
  }
}

.create-new-button {
  border-radius: 10px;
  border: 2px dashed #329993;
  background: #f7fdfd;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-bottom: 70px;

  span {
    color: #329993;
    font-family: $font-family-primary;
    font-size: 30.697px;
    font-style: normal;
    font-weight: 500;
    line-height: 52.624px;
    /* 171.429% */
  }
}

.datePckr__iconContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  transform: translate(-50%, 25%);
  width: 29.896px;
  height: 29.896px;
  border-radius: 5.61px;
}

.datePckr__iconContainer__start {
  background: #329993;
}

.datePckr__iconContainer__end {
  background: #ffaf2e;
}

.datePckr__start.input {
  cursor: pointer;
  padding-left: 24.08px !important;
  border: none !important;
  margin: 0 !important;
  border-radius: 11.626px !important;
  box-shadow: 0px 13.45759px 31.40104px 0px rgba(106, 106, 106, 0.2);
  min-width: 161.938px !important;
  height: 45px !important;
  color: #a0a0a0;
  font-family: $font-family-primary;
  font-size: $font-size-simple-text;
  font-style: normal;
  font-weight: 300;
  line-height: $font-height-simple-text;

  /* 160% */
  &.active,
  &:focus {
    box-shadow: 0px 13.45759px 31.40104px 0px rgba(106, 106, 106, 0.2) !important;
  }
}

.datePckr__end.input {
  cursor: pointer;
  padding-left: 24.08px !important;
  border: none !important;
  margin: 0 !important;
  border-radius: 11.626px !important;
  box-shadow: 0px 13.45759px 31.40104px 0px rgba(106, 106, 106, 0.2);
  min-width: 161.938px !important;
  height: 45px !important;
  color: #a0a0a0;
  font-family: $font-family-primary;
  font-size: $font-size-simple-text;
  font-style: normal;
  font-weight: 300;
  line-height: $font-height-simple-text;

  /* 160% */
  &.active,
  &:focus {
    box-shadow: 0px 13.45759px 31.40104px 0px rgba(106, 106, 106, 0.2) !important;
  }
}

.email-message {
  padding: 0.75rem 1rem;
  position: relative;
}

.custom_dropdown {
  .custom_dropdown_toggle {
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 !important;
    width: 41.765px !important;
    height: 41.765px !important;
    border-radius: 40.537px;
    background: white;
    box-shadow: 0px 4.91353px 13.5122px 0px rgba(0, 0, 0, 0.13);
  }

  .custom_dropdown_menu {
    padding-top: 0 !important;
    min-width: 41.765px !important;

    &.show {
      top: 35px !important;
      transform: none !important;
      box-shadow: none;
      background-color: transparent !important;
      border-top: none !important;
      border-top-left-radius: 0 !important;
      border-top-right-radius: 0 !important;
    }
  }

  .custom_dropdown_menu[data-popper-placement="top-start"] {
    position: absolute !important;
    inset: 0px auto auto 0px !important;
    transform: translate(0px, 42px) !important;
  }

  .custom_dropdown_item {
    animation-name: DropDownSlide;

    animation-duration: 0.3s;
    animation-fill-mode: both;
    margin-bottom: 5px;
    padding: 10px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: transparent !important;
    }
  }

  &.show {
    background: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(6.14190673828125px);
    border-radius: 40.537px;
    z-index: 99;
    position: absolute;
    height: 175.659px;
    box-shadow: 0px 4.91353px 13.5122px 0px rgba(0, 0, 0, 0.13);

    .custom_dropdown_toggle {
      box-shadow: none;
      background-color: transparent !important;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
    }
  }
}

.email-message-text {
  max-width: 260px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  // margin-right: 10px;
  margin-bottom: 2px;
  font: normal 400 12x $font-family-primary !important;
  color: #171c1b;
  line-height: normal;
}

.email-message-subject {
  max-width: 260px;
  color: #465352;
  font: normal 600 12px $font-family-primary !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.teaser {
  max-width: 300px;
  color: #465352;
  font: normal 400 12px $font-family-primary !important;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limite le texte à 2 lignes */
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
}

.email-message-date {
  overflow: hidden;
  color: #465352;
  text-align: right;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-family: $font-family-primary;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  /* 150% */
  max-width: 61px;
}

.mails-separator {
  border-top-color: #e3e8e7;
  position: absolute !important;
  width: auto !important;
  left: 24px;
  right: 24px;
  bottom: 0px;
  margin: 0 !important;
}

.realtime-map {
  .card-style {
    background-color: white !important;
  }

  .jvectormap-container {
    svg {
      path[fill="#563bff"] {
        fill: #ffa412;
      }
    }
  }
}

.task_list {
  .task-message {
    padding: 24px 12px 24px 9px;
    position: relative;
  }

  .task-message-text {
    position: relative;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-right: 10px;
    margin-bottom: 2px;
    font: normal 400 16px $font-family-primary !important;
    color: #171c1b;
    line-height: normal;
  }

  .task-info {
    margin-top: 4px;
    font-family: $font-family-primary;
    font-size: 11px;
    font-style: normal;
    line-height: normal;

    .task-by {
      color: #ffae2e;
      font-weight: 500;
      gap: 5px;
    }

    .task-to {
      color: #ff7049;
      font-weight: 500;
      gap: 5px;
    }
  }

  .tasks-separator {
    border-top-color: #e3e8e7;
    position: absolute !important;
    width: auto !important;
    left: 24px;
    right: 24px;
    bottom: 0px;
    margin: 0 !important;
  }

  .badge {
    height: 22px;
    padding-left: 12px;
    padding-right: 12px;
    border-radius: 48px;
    overflow: hidden;
    color: #fff;
    text-align: center;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: $font-family-primary;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
    /* 180% */
  }

  .checkbox-container {
    display: flex;
    align-items: center;

    .form-check-input {
      margin-top: 0;
      margin-right: 13px;
      width: 18px;
      height: 18px;
      border-radius: 6.756px;
      border: 1px solid #e3e8e7;
      background-color: white;

      &.active {
        border: none;
      }

      &:checked {
        border: none;
        background-color: #05b714;
        box-shadow: none;
      }
    }
  }
}

.global-view {
  .apex-charts {
    border-radius: 20px;
    background-color: #f8f9ff;
  }
}

.customLegend {
  margin-top: 5px;
  display: flex;

  .legend {
    margin-right: 8px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
  }

  span {
    color: #5e5e5e;
    height: fit-content;
    font-family: $font-family-primary;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
}

.client-satisfaction {
  .customLegend {
    margin-top: 50px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;

    .legend {
      margin-right: 19px;
      width: 20px;
      height: 20px;
      border-radius: 5px;
    }

    .dissatisfied {
      background: #329993;
    }

    .neutral {
      background: #563bff;
    }

    .satisfied {
      background: #fd9277;
    }

    span {
      color: #5e5e5e;
      font-family: $font-family-primary;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
