@import "../../../../Pages/Analytics/font-variables";
// 
// Page-title
// 

.page-title-box {
    padding-bottom: $grid-gutter-width;
    font-family: Montserrat ,sans-serif;
    .breadcrumb {
        background-color: transparent;
        padding: 0;
        font-family: Montserrat ,sans-serif;
    }

  .page-title {
    color: black;
    font-family: $font-family-tertiary;
    font-size: 28px !important;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }
  .page-title-right {
    align-items: center;
    gap: 20px;
  }
}

body[data-topbar="dark"] {
  .page-title{
    color: white;
  }
  .page-title-right {
    .input-group{
      background: #272B35 !important;
    }
  }
}
