@import '../../../../Pages/Analytics/font-variables';

// 
// calendar.scss
//

.fc-toolbar {
  h2 {
      font-size: 16px;
      line-height: 30px;
      text-transform: uppercase;
  }
}

.fc {
  th.fc-widget-header {
      background: $light;
      font-size: 13px;
      line-height: 20px;
      padding: 10px 0;
      text-transform: uppercase;
      font-weight: $font-weight-semibold;
  }
}

.fc-unthemed{
  .fc-content, 
  .fc-divider, 
  .fc-list-heading td, 
  .fc-list-view, 
  .fc-popover, 
  .fc-row, 
  tbody, 
  td, 
  th, 
  thead{
      border-color: $light;
  }
  td.fc-today {
      background: lighten($gray-200, 4%);
  }
}

.fc-button {
  background: $card-bg;
  border-color: $border-color;
  color: $gray-700;
  text-transform: capitalize;
  box-shadow: none;
  padding: 6px 12px !important;
  height: auto !important;
}

.fc-state-down,
.fc-state-active,
.fc-state-disabled {
  background-color: $primary;
  color: $white;
  text-shadow: none;
}

.fc-event {
  border-radius: 2px;
  border: none;
  cursor: move;
  font-size: 0.8125rem;
  margin: 5px 7px;
  padding: 5px 5px;
  text-align: center;
}

// .fc.fc-direction-ltr {
//   .fc .fc-daygrid-day-top
// }
.fc-view-harness {
  background-color: $white !important;
}

.fc-daygrid-event-dot, .fc-event-time {
  display: none !important;
}
.fc-event-title {
  text-align: left !important;
  font-family: $font-family-primary !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 133.9% !important;
  letter-spacing: 0.49px !important;
}
// .fc-daygrid-day-events,  {
  // position: absolute !important;
  // bottom: -5px !important;
  // width: 100% !important;
  // margin-top: auto !important;
// }

.fc-col-header-cell {
 background-color: $white !important;
}
.fc-col-header-cell-cushion {
  padding-top: 22px !important;
  padding-bottom: 22px !important;
  padding-left: 40px !important;
  padding-right: 0 !important;
  color: #6E7781 !important;
  text-align: left;
  font-family: $font-family-primary;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.fc-day-today {
  background: #FFFAF1 !important;
  .fc-daygrid-day-number {
    margin-top: 33px !important;
    background-color: transparent !important;
    color: #FF7049 !important;
    font-weight: 700 !important;
  }
}

div[class="fc-header-toolbar fc-toolbar fc-toolbar-ltr"] {
  margin-bottom: 45px !important;
  >.fc-toolbar-chunk {
    div {
      display: flex;
      align-items: center;

      button.fc-prev-button, button.fc-next-button {
        background-color: transparent;
        border-color: transparent;
        color: black;
      }

      h2 {
        color: #151D48;
        font-family: $font-family-primary;
        font-size: 24px;
        font-style: normal;
        font-weight: 500;
        line-height: 133.9%;
        /* 32.136px */
        letter-spacing: 2.4px;
      }
    }
  }
}



.fc-daygrid-day-top {
 flex-direction: row !important;
}

.fc-daygrid-day-number {
  margin-top : 34px !important;
  margin-left: 40px !important;
  height: fit-content !important;
  width: fit-content !important;
  color: #000 !important;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-size: 32.968px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;

}

#external-events .external-event {
  text-align: left!important;
  padding: 8px 16px;
}

.fc-event, .fc-event-dot{
  background-color: $primary;
}

.fc-event .fc-content{
  color: $white;
}


.div[class="fc-dayGridMonth-view fc-view fc-daygrid"] {
  border-radius: 10px !important;
}

// .table-bordered {
//   border: 1px solid #C7C7D2 !important;
//   border-radius: 10px !important;

// }

// .td {
//   border: 1px solid #C7C7D2 !important;
// }

.fc {
  .table-bordered {
    td, th {
      border-color: $table-group-separator-color;
    }
  }
  
  .fc-toolbar {
    @media (max-width: 575.98px) {
      display: block;
    }
    
      h2 {
          font-size: 16px;
          line-height: 30px;
          text-transform: uppercase;
      }

      @media (max-width: 767.98px) {

          .fc-left,
          .fc-right,
          .fc-center {
              float: none;
              display: block;
              text-align: center;
              clear: both;
              margin: 10px 0;
          }

          >*>* {
              float: none;
          }

          .fc-today-button {
              display: none;
          }
      }
      
      .btn {
          text-transform: capitalize;
      }

  }
}
.fc-bootstrap .fc-today.alert-info{
  background-color: $gray-300;
}

.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end.bg-dark {
  background-color: $black !important;
}

// RTL
[dir="rtl"] .fc-header-toolbar {
  direction: ltr !important;
}

[dir="rtl"] .fc-toolbar>*>:not(:first-child) {
  margin-left: .75em;
}

// Dark mode
body[data-sidebar="dark"] {
  button.fc-prev-button, button.fc-next-button, .fc-toolbar-title {
    color: #FFF !important;
  }
  .fc-col-header-cell-cushion {
    color: #FFA412 !important;
  }
  .fc-col-header-cell {
    border: 1px solid #363853 !important;
    background-color: #1E222C !important;
  }
  .table-bordered {
    border: 1px solid #363853 !important;
  }
  .fc {
    .table-bordered {
      td, th {
        border-color: #363853;
        background-color: #1E222C;
      }
    }
  }
  .fc-daygrid-day-number {
    color: #FFF !important;
  }
  .fc-day-today {
    background: #FFA412 !important;
    .fc-daygrid-day-number {
      color: #000 !important;
    }
  }
}

