.rdrDateRangePickerWrapper > .rdrDefinedRangesWrapper {
  display: none;
}

.rdrDefinedRangesWrapper {
  width: 180px;
}

.rdrStaticRangeSelected {
  color: #563bff !important;
}

.rdrInputRanges {
  display: none;
}

.rdrMonthAndYearPickers {
  display: none;
}

.rdrDateDisplayWrapper {
  display: none;
}

div.rdrMonth > div {
  text-align: center;
}

div.rdrMonth > div > button {
  position: relative;
  z-index: 1;
}

.rdrMonthAndYearWrapper {
  height: 0px;
  padding-top: 0px;
}
.rdrDayToday .rdrDayNumber span::after {
  background: black;
}

.rdrWeekDay,
.rdrMonthName {
  text-transform: capitalize;
}

.rdrStartEdge {
  border-radius: 50%;
  top: 3px;
  bottom: 3px;
  right: 7px !important;
  left: 7px !important;
  &::after {
    content: "";
    width: 50%;
    position: absolute;
    right: -23%;
    height: 93.7%;
    margin-top: 1px;
    z-index: -1;
    background-color: rgba(157, 165, 174, 0.23);
  }
}

.rdrEndEdge {
  border-radius: 50%;
  right: 7px !important;
  left: 7px !important;
  top: 3px;
  bottom: 3px;
  &::after {
    content: "";
    width: 50%;
    position: absolute;
    left: -23%;
    height: 93.8%;
    margin-top: 1px;
    z-index: -1;
    background-color: rgba(157, 165, 174, 0.23);
  }
}

.rdrEndEdge.rdrStartEdge {
  border-radius: 50% !important;
  &::after {
    display: none;
  }
}

.rdrDayEndOfWeek .rdrStartEdge,
.rdrDayStartOfWeek .rdrEndEdge {
  border-radius: 50% !important;
  &::after {
    display: none;
  }
}

.rdrInRange {
  top: 4px;
  right: 0px;
  bottom: 4px;
  left: 0px;
}
.rdrInRange {
  color: black !important;
}

button.rdrNextPrevButton {
  margin-bottom: -6%;
  margin-right: 3%;
}

button.rdrNextPrevButton {
  margin-bottom: -6%;
  margin-left: 3%;
}

.rdrDateRangePickerWrapper__custom {
  border-radius: 20px;
  box-shadow: 0px 13.45759px 31.40104px 0px rgba(106, 106, 106, 0.2);
  padding: 10px;
  background-color: #fff;
}

.rdrMonth {
  padding-bottom: 0;
}

.rdrEndEdge {
  color: rgb(0, 0, 0) !important;
}

.rdrStartEdge {
  color: rgb(0, 0, 0) !important;
}

div.rdrMonth > div > button > span {
  color: rgba(157, 165, 174, 0.23);
}

div.rdrMonth > div > button > span > span {
  color: black;
}

.rdrInRange ~ .rdrDayNumber span {
  color: black !important;
}
.rdrInRange {
  color: rgba(157, 165, 174, 0.23) !important;
}
.rdrDayNumber span {
  color: #1d2429;
}

.rdrDayStartPreview,
.rdrDayInPreview,
.rdrDayEndPreview {
  top: 3px;
  bottom: 3px;
}

.rdrDayStartPreview {
  border-top-left-radius: 46%;
  border-bottom-left-radius: 46%;
  left: 7px;
}

.rdrCalendarWrapper:not(.rdrDateRangeWrapper) .rdrDayHovered .rdrDayNumber {
  &::after {
    display: none !important;
  }
}

.rdrDayEndPreview {
  border-top-right-radius: 46%;
  border-bottom-right-radius: 46%;
  right: 7px;
}

.rdrDayStartOfMonth .rdrDayEndPreview {
  border-radius: 50%;
  left: 7px;
}
.rdrDayEndOfWeek .rdrDayStartPreview {
  border-radius: 50%;
  right: 7px;
}

.rdrDayStartPreview.rdrDayEndPreview {
  border-radius: 50%;
}

.rdrDayStartOfWeek .rdrDayEndPreview {
  left: 7px;
}

.rdrDayEndOfMonth .rdrDayStartPreview {
  right: 7px;
}

.rdrDayStartPreview,
.rdrDayEndPreview,
.rdrDayInPreview {
  color: rgb(86, 59, 255) !important;
}

hr {
  border-top-width: 2px !important;
}

.rdrValidate__button {
  font-weight: 600 !important;
  border-radius: 10px !important;
  background-color: black !important;
}

.rdrDateFrom,
.rdrDateTo {
  position: relative;
  padding: 7px 12px;
  border-radius: 10px;
  border: 1px solid rgba(9, 27, 61, 0.1) !important;
  color: black;
  > span {
    font-weight: 600;
    font-size: 12px;
    position: absolute;
    top: -26%;
    background-color: white;
    padding: 0 2px;
    color: rgba(9, 27, 61, 0.52);
  }
}
