

.parent{
    height: 90vh;
}

.header{
    position: sticky;
    top: 0px;
    margin-top: 20px;
    margin-left: 40px;
    display: flex;
    align-items: center;
    margin-right: 15px;
}

.step{
    font-weight: bold;
    color: #324199;
}

.total{
    font-weight: bold;
    color: #67748E;
    margin-right: 15px;
}

.roundDiv{
    height: 6px;
    width: 6px;
    border-radius: 50px;
    margin-right: 5px;
    background-color:#dadde3;
}

.bullet1{
    background-color: #2669F6;
}




.container{
    height: 100%;
    display: flex;
    justify-content: center;
    gap: 100px;
}




.left{
margin-top: 100px;
height: 400px;
width: 300px;
}

.title{
    font-size: larger;
    font-weight: bold;
    font-family: 'Segoe UI', sans-serif;
    color: #324199;
    width: 100%;
}

.wrapForm{
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    padding: 40px 0;
}

.wrapInput{
    position: relative;
}

.Input{
    padding: 8px;
    height: 40px;
    width: 100%;
    background-color: white;
    border: 2px solid rgba(211, 211, 211, 0.452);
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    color: black;
}

.Input:focus{
    outline: none;
    border: 2px solid #2669F6;
  }

.Input::placeholder{
    padding: 5px;
    font-weight: bold;
    font-size: 14px;
    color: lightgray;
}

.inputLabel{
    position: absolute;
    top: -20%;
    left: 2%;
    padding: 0 4px;
    background-color: white;
    font-size: 12px;
    color:lightgray;
}

.Input:focus + .inputLabel{
    color:#2669F6 ;
}

.text{
    margin: 20px 0;
    font-weight: bold;
}

.wrapColors{
    display: flex;
    position: relative;
    gap: 8px;
}

.bigRound {
    height: 30px;
    width: 30px;
    border-radius: 50px;
}
.bigRound:hover{
    cursor: pointer ;
}



.color1{
    background-image: linear-gradient(to bottom right, #2533d2, #50b5f4);
}
.color2{
    background-image: linear-gradient(to bottom right, #4630bc, #6f55de);
}
.color3{
    background-image: linear-gradient(to bottom right, #ec6671, #ed8c70);
}
.color4{
    background-image: linear-gradient(to bottom right, #c4e2f4, #f1eeed);
}
.color5{
    background-image: linear-gradient(to bottom right, #313943, #444f5b);
}

.more{
    border: solid 1px lightgray;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar{
    position: absolute;
    right: 0;
}


.wrapDropdown{
    margin-top: 20px;
    width: 100%;
    position: relative;
}

.dropDown{
    height: 40px;
    width: 100%;
    border: 2px solid rgba(211, 211, 211, 0.452);
    border-radius: 3px;
    font-size: 14px;
    font-weight: bold;
    padding: 0 5px;
}

.dropDownLabel{
    position: absolute;
    top: -20%;
    left: 2%;
    padding: 0 4px;
    background-color: white;
    font-size: 12px;
    color:lightgray;
}

.dropDown:focus{
    outline: none;
    border: 2px solid #2669F6;
}
.dropDown:focus + .dropDownLabel{
    color:#2669F6 ;
}

















.right{
    position: relative;
margin-top: 100px;
background-color: white;
height: 500px;
width: 300px;
border-radius: 15px;
box-shadow: lightgray 0px 10px 20px, lightgray 0px 6px 6px;
}

.topChat{
    height: 100px;
    border-radius: 15px 15px 0px 0;
    background-image: linear-gradient(to bottom right, #2533d2, #50b5f4);
    display: flex;
    align-items: center;
}

.profil{
    height: 50px;
    width: 50px;
    border-radius: 50px;
    margin-right: 15px;
    margin-left: 20px;
    background-color:#dadde3;
}

.wrapDiv{
    color: white;
    margin-right: 70px;
}

.aboveNom{
    font-size: small;
}

.nom{
    font-size: large;
}

.topIcon{
      height: 25px;
      width: 25px;
}

/* .waveDiv{
    
} */

.messages{
    display: flex;
    flex-direction: column;
}

.singleMsg{
    font-size: small;
    border-radius: 20px;
    padding: 8px 10px;
    max-width: 80%;
}

.entrant{
    background-color: #f0f2f6;
    margin: 10px 20px;
}

.sortant{
    margin: 2px 20px;
    align-self: flex-end;
    color: white;
    background-image: linear-gradient(to bottom right, #2533d2, #50b5f4);
}

.hr{
    align-self: center;
    margin-top : 20px;
    margin-bottom : 10px;
    width : 85% ;
    opacity: 0.4;
}

.input{
    margin-left: 20px;
    margin-bottom: 16px;
    color: #d3d3d3;
}

.iconsWrap{
    display: flex;
    align-items: center;
}

.bottomIcon{
    margin-left: 16px;
    height: 20px;
    width: 20px;
}

.textCredit{
    margin-left: 20%;
    font-size: 10px;
    color: lightgray;
}

.logoTidio{
    opacity: 0.3;
}

.tidio{
    font-size: 10px ;
    font-weight:bold;
    opacity: 0.5;
}

.sendButton{
    position: absolute;
    bottom: 8%;
    right: -6%;
    height: 50px;
    width: 50px;
    background-image: linear-gradient(to bottom right, #2533d2, #50b5f4);
    border-radius: 50px;
    box-shadow: #adb2eb 0px 5px 15px 1px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.footer{
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: center;
    padding: 20px 0;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px 20px;
}

.button{
    height: 40px;
    width: 150px;
    font-size: smaller;
    background-color: #2669F6;
    border: 1px #2669F6;
    border-radius: 3px;
    color: white;
    box-shadow: none;
}

.button:active{
    background-color: #2949bd;
}