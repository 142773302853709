// Custom Font
$font-family-primary : "Montserrat", sans-serif;
$font-family-secondary: "Roboto", sans-serif;
$font-family-tertiary: "Poppins", sans-serif;

// Custom Font Size & Height
$font-size-simple-text: 14px;
 $font-height-simple-text : 1.25rem;
$font-size-title-text: 16px;
// $font-height-title-text : 1.25rem;


.font-primary {
    font-family: $font-family-primary !important;
}

.font-secondary {
    font-family: $font-family-secondary;
}

.font-tertiary {
    font-family: $font-family-tertiary;
}

.simple-text {
    font-size: $font-size-simple-text;
    // line-height: $font-height-simple-text;
}
.title-text {
    font-size: $font-size-title-text;
    // line-height: $font-title-simple-text;
}