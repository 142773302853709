.editor {
  color: #171c1b;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
  padding-bottom: 0 !important;
  // border: 1px solid #ddd;
  cursor: text;
  padding: 16px;
  border-radius: 2px;
  margin-bottom: 2em;
  // box-shadow: inset 0px 1px 8px -3px #ABABAB;
  // background: #fefefe;

  .DraftEditor-editorContainer {
    .public-DraftEditor-content {
      min-height: 78px !important;
    }
  }
  .custom-toolbar {
    .sendEmail___button-container {
      align-items: center;
    }
    .draftJsToolbar__toolbar__dNtBH {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
      box-shadow: none;
      border: none;
    }

    .draftJsEmojiPlugin__emojiSelect__34S1B {
      width: 36px;
      height: 34px;
      display: flex;
      justify-content: center;
      .draftJsEmojiPlugin__emojiSelectButton__3sPol {
        line-height: 1em;
        font-size: 2em;
        height: fit-content;
        width: fit-content;
        border: 0 solid #ddd;
        border-radius: 0;
      }
      .draftJsEmojiPlugin__emojiSelectButtonPressed__2Tezu {
        line-height: 1em;
        font-size: 2em;
        height: fit-content;
        width: fit-content;
        border: 0 solid #ddd;
        border-radius: 0;
      }
    }
    .draftJsEmojiPlugin__emojiSelectPopover__1J1s0 {
      bottom: 48px !important;
    }

    button {
      color: #272b35;
      padding: 0;
      background-color: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .aiButton {
      &:hover {
        -webkit-animation: rotate-center 0.6s ease-in-out both;
        animation: rotate-center 0.6s ease-in-out both;
      }
    }
  }

  .headline-button-wrapper {
    display: inline-block;
  }

  .sendEmail___button {
    display: flex;
    align-items: center;
    justify-content: center;
    border: none !important;
  }
  .headline-button {
    background: #fbfbfb;
    color: #888;
    font-size: 18px;
    border: 0;
    padding-top: 5px;
    vertical-align: bottom;
    height: 34px;
    width: 36px;
  }

  .headline-button:hover,
  .headline-button:focus {
    background: #f3f3f3;
  }
}

.editor :global(.public-DraftEditor-content) {
  min-height: 140px;
}

@-webkit-keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotate-center {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

body[data-sidebar="dark"] {
  .editor {
    color: #fff !important;
    .custom-toolbar {
      .draftJsToolbar__toolbar__dNtBH {
        background: transparent !important;
        button {
          background-color: transparent !important;
        }
        .draftJsEmojiPlugin__emojiSelectButton__3sPol {
          color: #fff !important;
        }
        .fileChooser,
        .moreButton {
          path {
            fill: #fff !important;
          }
        }
        .sendEmail___button-container {
          .sendEmail___button {
            background: transparent !important;
            path {
              fill: #fff !important;
            }
          }
        }
      }
    }
  }
}
