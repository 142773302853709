@import './../../Pages/Analytics/font-variables';

.bull {
  background-image: linear-gradient(to bottom right, rgb(86, 59, 255), rgb(189, 180, 252));
  height: 60px;
  width: 60px;
  border-radius: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* display: none; */
}

.robot {
  height: 30px;
  width: 30px;
}

.body {
  position: relative;
  margin: 0;
  background-color: white;
  font-family: "Segoe UI", sans-serif;
  color: black;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.parent {
  height: 90vh;
}

.header {
  position: sticky;
  top: 0px;
  padding-top: 20px;
  margin-left: 40px;
  display: flex;
  align-items: center;
  margin-right: 15px;
  padding-bottom: 20px;
  z-index: 1;
}

.step {
  font-weight: bold;
  color: #324199;
}

.total {
  font-weight: bold;
  color: #67748e;
  margin-right: 15px;
}

.roundDiv {
  height: 6px;
  width: 6px;
  border-radius: 50px;
  margin-right: 5px;
  background-color: #dadde3;
}

.bullet1 {
  background-color: #2669f6;
}

.container {
  height: 100%;
  display: flex;
  justify-content: center;
}

.centerDiv {
  margin-top: 50px;
  height: 800px;
  width: 800px;
  display: flex;
  flex-direction: column;
}

.mainWrap {
  width: 100%;
  height: 100%;
  margin-top: 30px;
  display: flex;
  justify-items: center;
}

.left {
  height: 400px;
  width: 300px;
}

.title {
  font-size: larger;
  font-weight: bold;
  font-family: "Segoe UI", sans-serif;
  color: #324199;
  width: 100%;
}

.wrapForm {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 40px 0;
}

.wrapInput {
  position: relative;
}

.Input {
  padding: 8px;
  height: 40px;
  width: 100%;
  background-color: white;
  border: 2px solid rgba(211, 211, 211, 0.452);
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
  color: black;
}

.Input:focus {
  outline: none;
  border: 2px solid #2669f6;
}

.Input::placeholder {
  padding: 5px;
  font-weight: bold;
  font-size: 14px;
  color: lightgray;
}

.inputLabel {
  position: absolute;
  top: -20%;
  left: 2%;
  padding: 0 4px;
  background-color: white;
  font-size: 12px;
  color: lightgray;
}

.Input:focus + .inputLabel {
  color: #2669f6;
}

.text {
  margin: 20px 0;
  font-weight: bold;
}

.wrapColors {
  display: flex;
  position: relative;
  gap: 8px;
}

.bigRound {
  height: 30px;
  width: 30px;
  border-radius: 50px;
  cursor: pointer;
}

/* .bigRound:hover{

} */

.color1 {
  background-image: linear-gradient(to bottom right, #2533d2, #50b5f4);
}

.color2 {
  background-image: linear-gradient(to bottom right, #4630bc, #836ee2);
}

.color3 {
  background-image: linear-gradient(to bottom right, #ec6671, #ed8c70);
}

.color4 {
  background-image: linear-gradient(to bottom right, #c4e2f4, #f1eeed);
}

.color5 {
  background-image: linear-gradient(to bottom right, #313943, #5c6b7c);
}

.more {
  border: solid 1px lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.avatar {
  cursor: pointer;
  position: absolute;
  right: 0;
}

.wrapDropdown {
  margin-top: 20px;
  width: 100%;
  position: relative;
}

.dropDown {
  height: 40px;
  width: 100%;
  border: 2px solid rgba(211, 211, 211, 0.452);
  background-color: white;
  border-radius: 3px;
  font-size: 14px;
  font-weight: bold;
  padding: 0 5px;
}

.dropDownLabel {
  position: absolute;
  top: -20%;
  left: 2%;
  padding: 0 4px;
  background-color: white;
  font-size: 12px;
  color: lightgray;
}

.dropDown:focus {
  outline: none;
  border: 2px solid #2669f6;
}

.dropDown:focus + .dropDownLabel {
  color: #2669f6;
}

.rightWrap {
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
}

.chat-content {
  display: flex;
  flex-direction: column;
  position: relative;
  opacity: 1;
  height: 500px;
  width: 330px;
  max-height: 500px;
  border-radius: 15px;
  transition: all 250ms ease-out;
  justify-self: center;
  box-shadow: lightgray 0px 10px 20px, lightgray 0px 6px 6px;
  background-color: #f6f6f7;
}
.wave_area {
  display: flex;
}
.messages {
    height: 100vh;
    overflow-y: scroll;
}
.chat_messages {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  overflow-x: hidden;
  padding-top: 0%;
  list-style: none;
}
.chat-header {
  height: 100px;
  width: 100%;
  border-radius: 15px 15px 0px 0;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  padding: 10px 16px;
  -webkit-box-shadow: 0px 24px 8px 0px rgba(227, 227, 227, 0.34);
  -moz-box-shadow: 0px 24px 8px 0px rgba(227, 227, 227, 0.34);
  box-shadow: 0px 24px 8px 0px rgba(227, 227, 227, 0.34);
}
.chat_footer {
  margin-top: 0px;
  flex-shrink: 0;
  display: flex;
  max-height: 106px;
  background: transparent;
}

.chat_profil {
  border-radius: 100%;
  background-color: #dadde3;
}

.wrapDiv {
  color: white;
  margin-right: 70px;
}

.aboveNom {
  font-size: small;
}

.chat_nom {
  font-size: 14px;
  font-family: $font-family-primary;
  line-height: $font-height-simple-text;
  font-weight: 600;
  height: fit-content;
  max-width: 80px;
  white-space: nowrap;
}

.topIcon {
  display: flex;
  justify-content: center;
  align-items: center;
}

.normalText {
  font-size: smaller;
  user-select: none;
  color: white;
  align-items: center;
  left: 6%;
  bottom: -20%;
  z-index: 2;
}

.svg {
  width: 100%;
  position: absolute;
}

.chat_singleMsg {
  li {
    font-family: $font-family-primary;
    line-height: $font-height-simple-text;
    color: black;
    font-size: small;
    border-radius: 10px;
    padding: 10px;
    max-width: 80%;
  }
}

.chat_entrant {
  li {
      hyphens: auto;
      -webkit-hyphens: auto;
      -ms-hyphens: auto;
      word-break: break-word;
      background-color: #ffffff;
      margin: 10px 0px 10px 8px;
      -webkit-box-shadow: 10px 10px 8px 0px rgba(227, 227, 227, 0.34);
      -moz-box-shadow: 10px 10px 8px 0px rgba(227, 227, 227, 0.34);
      box-shadow: 10px 10px 8px 0px rgba(227, 227, 227, 0.34);
    }
}

.chat_sortant {
  margin-top: 16px;
  flex-flow: row-reverse;

  li {
    hyphens: auto;
    -webkit-hyphens: auto;
    -ms-hyphens: auto;
    word-break: break-word;
    margin-top: 2px;
    margin-right: 8px;
    align-self: flex-end;
    background-color: #fbd000;
  }
}

.hr {
  align-self: center;
  margin-bottom: 10px;
  opacity: 0.4;
}

.the_input {
  color: black;
  background-color: white;
  border: 1px solid #80808040 !important;
  border-radius: 10px;
  padding: 8px;
  width: 100%;
}

.text_area {
  border: none;
  resize: none;
}

.chat_iconsWrap {
  display: flex;
  align-items: center;
}

.chat_bottomIcon {
  margin-left: 16px;
  height: 20px;
  width: 20px;
}

.textCredit {
  margin-left: 20%;
  font-size: 10px;
  color: lightgray;
}

.logoTidio {
  opacity: 0.3;
}

.tidio {
  font-size: 10px;
  font-weight: bold;
  opacity: 0.5;
}

.sendButton {
  position: absolute;
  bottom: 8%;
  right: -6%;
  height: 50px;
  width: 50px;
  background-image: linear-gradient(to bottom right, #2533d2, #50b5f4);
  border-radius: 50px;
  box-shadow: #adb2eb 0px 5px 15px 1px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.action_button {
  height: fit-content;
  background: none;
  box-shadow: none;
  border: none;
}