@import './../../../../Pages/Analytics/font-variables';


//
// Welcome Section
//
.welcome-section__container {
  // padding: 0 !important;
  // border-radius: 20px;
  // background: #fff;
  // box-shadow: 0px 4px 32px 0px rgba(0, 0, 0, 0.05);
  margin-bottom: 80px;

  .welcome-section__right {
    margin: 18px 0 38.34px 0px;
    flex-direction: column;

    .dateAndhour__conatainer {
      width: fit-content;
      height: 27.937px;
      align-items: center;
      padding: 6.07px 7.29px 4.86px 8.5px;
      border-radius: 4.859px;
      background: #f7f8fb;

      span {
        color: #091b3d;
        text-align: center;
        font-family: $font-family-primary;
        font-size: 12.146px;
        font-style: normal;
        line-height: 33.153px;

        /* 272.943% */
        &.date {
          font-weight: 300;
        }

        &.hour {
          font-weight: 600;
        }
      }
    }

    .greetings__container {
      // padding: 12px 0 20.65px 0;
      gap: 5px;
      display: flex;
      // flex-direction: column;

      .greetings-text {
        // color: #ff7049;
        color: black;
        // font-family: $font-family-primary;
        font-family: $font-family-tertiary;
        // font-size: 21.864px;
        font-size: 32px;
        font-style: normal;
        // font-weight: 500;
        font-weight: 600;
        // line-height: 112.5%;
      }

      .employee-name {
        // color: #091b3d;
        color: black;
        // font-family: $font-family-primary;
        font-family: $font-family-tertiary;
        // font-size: 38.869px;
        font-size: 32px;
        font-style: normal;
        font-weight: 600;
        // line-height: 112.5%;
        text-transform: capitalize;
      }

      .hello-icon {
        height: 40px;
        width: 40px;
      }
    }
    .greetings-text__sub {
      font-family: $font-family-primary;
    }

    
  }

  .welcome-section__left {
    // margin: 18px 0 38.34px 0px;
    // width: 547px;
    width: 40%;
    align-items: center;
    gap: 5%;
    // min-height: 265px;
    // height: 100%;
    // position: relative;

    .view-tutorial-button {
      // width: fit-content;
      border: none;
      border-radius: 18px;
      color: #fff;
      // background-color: #ff7049;
      padding: 13.36px 10.93px 12.15px 12.15px;
      // box-shadow: 0px 19.683547973632812px 45.92827606201172px rgba(255, 112, 73, 0.2);
      font-weight: 500;
      font-family: $font-family-primary;
      font-size: $font-size-simple-text;
      line-height: $font-height-simple-text;
      gap: 8px;
      min-width: fit-content;
      width: 36%;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .black-button {
      background-color: black;
    }
    .primary-button {
      background-color: #563BFF;
    }
   
  }
}

body[data-topbar="dark"] {
  .welcome-section__container {
    background: #1E222C;
  }

  .employee-name {
    color: #fff !important;
  }
  g[mask='url(#mask0_243_5109)']{
    path[fill="#FFFAF1"]{
      fill: #363A45;
    }
    path[stroke="#FFFAF1"]{
      stroke: #363A45;
    }
  }
}